import { graphql } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { SEO } from "../components/SEO";
import { PageProps } from "../types/global";
import { navigate } from 'gatsby-link';
import { BsChatText } from "react-icons/bs";
import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import { Collapse } from 'antd';

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            
          }
    }
`;

const MainText = styled.p`
    display: table-row;
`;
const MainTextCenter = styled(MainText)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
    gap: 2rem;
`;
const Label = styled.label`
    display: table-cell;
    font-size: 1.2em;
    color: #000000;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 1rem;
`;
const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0 0 10rem 0;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255,255,255,0.47522759103641454) 0%, rgba(44,204,214,0.2903536414565826) 100%);
    @media (max-width: 900px) {
        justify-content: flex-start;
    }
`;
const Forms = styled.form`
    display: flex;
    flex-flow: column nowrap;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 50px;
    @media(max-width: 900px) {
        padding: 0;
        width: 100vw;
        justify-content: flex-start;
    }
`;

const Input = styled.input`
    background: transparent;
    width: 100%;
    clear: both;
    color: #010101;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: transparent;
    border-color: #e2e4e4;
`;
const Input2 = styled(Input)`
    margin: 0;
    padding: 0;
    width: unset;
`;

const TextArea = styled.textarea`
    background: transparent;
    width: 100%;
    color: #bbbbbb;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
`;

const Button1 = styled.button`
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #161616;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
    @media (max-width: 900px) {
        justify-content: flex-start;
    }
`;

const ContactUS = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    color: #000000;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
    padding: 5rem 0 1rem 0;
    
`;

const Inline = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: start;
    flex-flow: column wrap;
    p {
        width: 7rem;
        margin: 0;
    }
`;
const ContainerData = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: flex-start;
`;
const ContainerData2 = styled(ContainerData)`
    flex-flow: column wrap;
`;
const Align = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    padding: 1rem;
    @media (max-width: 900px) {
        justify-content: flex-start;
        padding: 1rem 0;
    }
`;
const Align2 = styled.div`
    display: inline-flex;
    padding: 1rem;
    @media(max-width: 900px) {
       justify-content: flex-start;
       flex-flow: column nowrap;
    }
`;
const DivAlign = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;
const Visbile1 = styled.div`
    
    @media(max-width: 900px) {
       display: none; 
    }
`;

const Visbile2 = styled.div`
    @media(min-width: 901px) {
       display: none; 
    }
`;
const Select = styled.select`
    @media(max-width: 900px) {
       width: 100%; 
    }
`;
const Label1 = styled(Label)`
    padding-left: 4rem;
    width: 20rem;
    @media(max-width: 900px) {
        padding-left: 1rem;
    }
`;
const { RangePicker } = DatePicker;

function encode(data: { [x: string]: string | number | boolean; "form-name"?: any; }) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}
const { Panel } = Collapse;
const EnquirePage: React.FunctionComponent<PageProps> = (props) => {
    const [state, setState] = React.useState({})

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }
    const [submitted, setSubmitted] = useState(false)
    const submit = (token: any) => { }
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    return (
        <>

            <SEO
                lang={props.pageContext.language}
                title="Contact Me"
                description="Wanderlust Tourisms India"
                keywords={["Wanderlust Tourisms India"]}
                url={props.location.pathname}
            />
            <Container>
                <ContactUS>ENQUIRE</ContactUS>
                <Forms
                    name="Enquire-form"
                    method="post"
                    action="/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="Enquire" />
                    <p hidden>
                        <label>Please provide me these information to suggest you better.<input name="bot-field" onChange={handleChange} />
                        </label>
                    </p>
                    <Collapse defaultActiveKey={['1']} onChange={onChange} style={{ background: "transparent", border: "none" }} ghost>
                        <Panel header="Personal Data" key="1" style={{ background: "transparent" }}>
                            <ContainerData>
                                <MainText>
                                    <Label>Full Name: <Input type="text" name="name" onChange={handleChange} /></Label>
                                </MainText>
                                <MainText>
                                    <Label>Phone: <Input type="tel" name="phone" onChange={handleChange} /></Label>
                                </MainText>
                                <MainText>
                                    <Label>Email ID: <Input type="email" name="email" onChange={handleChange} /></Label>
                                </MainText>
                                <MainText>
                                    <Label>Nationality <Input type="text" name="Nationality" onChange={handleChange} /></Label>
                                </MainText>
                            </ContainerData>
                        </Panel>
                    </Collapse>

                    <Collapse defaultActiveKey={['1']} onChange={onChange} style={{ background: "transparent", border: "none" }} ghost>
                        <Panel header="Destination and Dates" key="1">
                            <ContainerData2>
                                <MainText>
                                    <Align>
                                        <Label htmlFor="destination">DESTINATION</Label>
                                        <select style={{ background: "transparent", padding: ".2rem" }} name="destination" id="destination" onChange={handleChange}>
                                            <option value="">--Please choose Destination--</option>
                                            <option value="thailand">🇹🇭 Thailand</option>
                                            <option value="bali">🇮🇩 Bali</option>
                                            <option value="maldives">🇲🇻 Maldives</option>
                                            <option value="mauritius">🇲🇺 Mauritius</option>
                                            <option value="singapore">🇸🇬 Singapore</option>
                                            <option value="malaysia">🇦🇪 Dubai</option>
                                            <option value="mauritius">🇮🇳 India</option>
                                            <option value="singapore">🇹🇷 Turkey</option>
                                            <option value="malaysia">🇸🇨 Seyschells</option>
                                        </select>
                                    </Align>

                                </MainText>
                                <MainText>
                                <Align><Label>Flights from <Input type="text" name="Flight" onChange={handleChange} /></Label></Align>
                                </MainText>
                                {/* <MainText>
                                    <Visbile1>
                                        <Align>
                                    <Label>Travel Dates <br /></Label>
                                        <Space direction="vertical" size={12} style={{ color:"black"}}>
                                            <RangePicker bordered={true} name="Date" id="date" style={{ borderRadius: "10px", width: "30rem", height: "4rem", color:"black"}} onChange={handleChange}/>
                                        </Space>
                                    </Align>
                                    </Visbile1>
                                </MainText> */}
                                <ContainerData>
                                    <MainText>
                                        <Label>Travel Dates</Label><br/>
                                        <Label>
                                        From 
                                        <Input type="date" name="Start date" id="date" onChange={handleChange} /></Label>
                                        
                                            <Label>To 
                                        <Input type="date" name="End date" id="date" onChange={handleChange} /></Label>
                                </MainText>
                                </ContainerData>
                                
                                <MainText>
                                <Align>
                                    <Label htmlFor="Travellers">Number of Travellers </Label><br />
                                        <ContainerData>
                                            <Align>
                                                <Label htmlFor="adult"><p>Adult<br />(Above 12+)</p></Label>
                                                <select style={{ background: "transparent", padding: ".2rem", width: "6rem", textAlign: "center" }} name="adult" id="adult" onChange={handleChange}>
                                                    <option value=""></option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9+">9+</option>
                                                </select>
                                            </Align>
                                            <Align>
                                                <Label htmlFor="Children"><p>Children<br />(2 - 12)</p></Label>
                                                <select style={{ background: "transparent", padding: ".2rem", width: "6rem", textAlign: "center" }} name="Children" id="Children" onChange={handleChange}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9+">9+</option>
                                                </select>
                                            </Align>
                                            <Align>
                                                <Label htmlFor="Infants"><p>Infants<br />(Below 2)</p></Label>
                                                <select style={{ background: "transparent", padding: ".2rem", width: "6rem", textAlign: "center", height: "3rem" }} name="Infants" id="Infants" onChange={handleChange}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9+">9+</option>
                                                </select>
                                            </Align>
                                        </ContainerData>
                                    </Align>
                                </MainText>

                            </ContainerData2>

                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['1']} onChange={onChange} style={{ background: "transparent", border: "none" }} ghost>
                        <Panel header="Tour Preference" key="1">
                            <ContainerData2>
                                <MainText>
                                <Align>
                                    <Label>Number of Travellers <br />
                                        <ContainerData>
                                            <Align>
                                                <Label htmlFor="tourtype"><p>Tour type</p></Label>
                                                <Select style={{ background: "transparent", padding: ".2rem", width: "20rem", textAlign: "center" }} name="tourtype" id="tourtype" onChange={handleChange}>
                                                    <option value="">--Please choose Your Tour Type--</option>
                                                    <option value="Friends">Friends</option>
                                                    <option value="Family">Family</option>
                                                    <option value="Couple">Couple</option>
                                                </Select>
                                            </Align>
                                        </ContainerData>

                                    </Label>
                                    </Align>
                                </MainText>
                                <MainText>
                                    
                                    <Label style={{paddingLeft: "2rem"}}>Hotel category</Label><br/>
                                        <Align2>
                                            <DivAlign>
                                                <Input2 style={{  }} type="checkbox" id="Hotel" name="Hotel" value="3 Star" onChange={handleChange} /><Label style={{ color: "goldenrod" }} htmlFor="3 Star">★★★☆☆</Label>
                                            </DivAlign>
                                            
                                            <DivAlign>
                                                <Input2 style={{  }} type="checkbox" id="Hotel" name="Hotel" value="4 Star" onChange={handleChange} />
                                                <Label style={{ color: "goldenrod" }} htmlFor="4 Star">★★★★☆</Label>
                                            </DivAlign>    
                                            
                                            <DivAlign>
                                                <Input2 style={{ }} type="checkbox" id="Hotel" name="Hotel" value="5 Star" onChange={handleChange} />
                                            <Label style={{ color: "goldenrod" }} htmlFor="5 Star">★★★★★</Label>
                                            </DivAlign>
                                            
                                            <DivAlign>
                                                 <Input2 style={{  }} type="checkbox" id="Hotel" name="Hotel" value="5 Star Luxury" onChange={handleChange} />
                                                <Label style={{ color: "goldenrod" }} htmlFor="5 Star Luxury">★★★★★★</Label>
                                            </DivAlign>
                                        </Align2>
                                </MainText>
                                <MainText>
                                    <Align>
                                        <Label htmlFor="meals">Meals</Label>
                                        <select style={{ background: "transparent", padding: ".2rem" }} name="Meals" id="Meals" onChange={handleChange}>
                                            <option value="">--Please choose Meal Preference--</option>
                                            <option value="Breakfast">Breakfast (CP Plan)</option>
                                            <option value="MAP">MAP Plan (Breakfast and Dinner Included)</option>
                                            <option value="AP">AP Plan (All Meals)</option>
                                        </select>
                                    </Align>

                                </MainText>
                                <MainText>
                                <Align>
                                    <Label>Meals Preference</Label><br />
                                    <Align2>
                                        <Label htmlFor="Veg">Veg</Label>
                                        <Input2 style={{ margin: "1rem" }} type="checkbox" id="preference" name="preference" value="Veg" onChange={handleChange} />
                                        <Label htmlFor="NonVeg">Non-Veg</Label>
                                        <Input2 style={{ margin: "1rem" }} type="checkbox" id="preference" name="preference" value="NonVeg" onChange={handleChange} />
                                    </Align2>
                                    </Align>
                                </MainText>
                                <MainText>
                                    <Align>
                                        <Label htmlFor="planning">How soon are you planning to travel</Label>
                                        <select style={{ background: "transparent", padding: ".2rem" }} name="planning" id="planning" onChange={handleChange}>
                                            <option value="">--Please choose an Option--</option>
                                            <option value="Immediate">Immediate</option>
                                            <option value="Week">Within a Week</option>
                                            <option value="Month">Within a Month</option>
                                        </select>
                                    </Align>
                                </MainText>
                                <MainText>
                                    <Align>
                                        <Label htmlFor="Transfers">Transfers</Label>
                                        <select style={{ background: "transparent", padding: ".2rem" }} name="transfers" id="transfers" onChange={handleChange}>
                                            <option value="">--Please choose Transfers--</option>
                                            <option value="Private">Private</option>
                                            <option value="Shared">Shared</option>
                                        </select>
                                    </Align>

                                </MainText>
                                <MainText>
                                <Align>
                                    <Label>Approx perhead budget <Input type="number" name="Budget" onChange={handleChange} /></Label>
                                
                                </Align>
                                   </MainText> 
                            </ContainerData2>
                        </Panel>
                    </Collapse>
                    <MainText>
                        <Label1>Remark <BsChatText /><TextArea name="remark" onChange={handleChange}></TextArea></Label1>
                    </MainText>
                    <MainTextCenter>
                        <Button1 data-action='submit' onClick={() => setSubmitted(true)} type="submit">Send</Button1>
                        <Button1 type="reset" value="Reset">Reset Form</Button1>
                    </MainTextCenter>
                </Forms>
            </Container>
        </>
    )
}

export default EnquirePage

